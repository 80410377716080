<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->
    <v-dialog v-model="dialog" class="dialogdetail" :fullscreen="isMobile" max-width="400">
      <v-card :loading="loading">
        <v-card-title>Verification</v-card-title>
        <v-card-text>
          <v-textarea v-model="verification" flat auto-grow outlined hide-details></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="closeDocument()">Submit</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      verification: "",
    }
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
      this.verification = "";
    },
    closeDialog() {
      this.dialog = false;
      this.verification = "";
    },
    closeDocument() {
      let post = async () => {
        this.loading = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.id);
        formData.append("verification", this.verification);
        await this.$axios
          .post("cpar/close", formData)
          .then((res) => {
            this.loading = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.closeDialog();
          })
          .catch((error) => {
            this.loading = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Close document?", post);
    },
  }
}
</script>